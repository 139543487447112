.top {
  display: flex;
  overflow: hidden; }
  .top .nav {
    width: 300px;
    height: min-content;
    position: relative; }
    @media (max-width: 992px) {
      .top .nav {
        width: auto; } }
    .top .nav.active {
      position: fixed;
      z-index: 100;
      top: 77px; }
    .top .nav:hover .nav_items {
      border-radius: 0px 0px 0px 8px; }
      .top .nav:hover .nav_items::before {
        content: ''; }
    .top .nav__title {
      width: 300px;
      height: 50px;
      border-radius: 8px 8px 0 0;
      text-align: left;
      background: #54AF37;
      display: flex;
      align-items: center;
      color: white;
      border: 1px solid #F2F2F2; }
      @media (max-width: 992px) {
        .top .nav__title {
          display: none; } }
      .top .nav__title.active {
        border-radius: 8px; }
      .top .nav__title img {
        margin: 20px; }
  .top .main {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-left: 20px; }
    @media (max-width: 992px) {
      .top .main {
        margin-left: 0px; } }
    .top .main .nav_hor {
      margin: 15px 0 25px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      @media (max-width: 992px) {
        .top .main .nav_hor {
          display: none; } }
      .top .main .nav_hor .language {
        position: absolute;
        right: 0;
        z-index: 10; }
      .top .main .nav_hor a {
        color: #333333;
        font-weight: bold;
        margin-right: 10px;
        width: auto !important; }
      .top .main .nav_hor_mobile {
        display: none;
        position: fixed;
        top: 100px;
        left: 0;
        z-index: 11;
        background: white;
        width: 295px;
        height: calc(100% - 100px);
        border-radius: 0px; }
        .top .main .nav_hor_mobile_container {
          overflow-y: auto;
          height: calc(100% - 56px); }
        .top .main .nav_hor_mobile .nav_hor_wrapper {
          display: flex;
          flex-direction: column;
          margin: 30px 0 0 20px; }
          .top .main .nav_hor_mobile .nav_hor_wrapper .header_another_phone {
            position: static; }
          .top .main .nav_hor_mobile .nav_hor_wrapper .header_main_phone_info {
            justify-content: flex-start; }
          .top .main .nav_hor_mobile .nav_hor_wrapper .header_main_phone {
            margin-bottom: 0; }
          .top .main .nav_hor_mobile .nav_hor_wrapper .schedule {
            color: #737988;
            font-weight: 500;
            font-size: 13px; }
          .top .main .nav_hor_mobile .nav_hor_wrapper a {
            color: #333333;
            font-weight: bold;
            margin-bottom: 25px; }
          .top .main .nav_hor_mobile .nav_hor_wrapper .number-link {
            margin-bottom: 0px; }
        .top .main .nav_hor_mobile .header_mobile_soc__block {
          width: 295px;
          position: fixed;
          bottom: 0; }
          .top .main .nav_hor_mobile .header_mobile_soc__block .header_lang__block a {
            font-weight: normal;
            font-size: 18px; }
            .top .main .nav_hor_mobile .header_mobile_soc__block .header_lang__block a.active {
              font-weight: bold; }
        .top .main .nav_hor_mobile.active {
          display: block; }

.header_sociate {
  transition: all .2s;
  margin-right: 10px; }
  .header_sociate__block {
    display: flex;
    align-items: center;
    margin-right: 20px; }
  .header_sociate:hover {
    transform: scale(1.1); }

.header_mobile_soc__block {
  display: flex;
  width: 100%;
  background: linear-gradient(130.41deg, #54AF37 26.74%, #104511 73.26%);
  justify-content: space-between;
  padding: 10px 15px; }
  .header_mobile_soc__block .header_lang {
    color: #fff;
    font-weight: normal; }
    .header_mobile_soc__block .header_lang.active {
      font-weight: bold; }

.nav_items {
  border-radius: 0px 0px 8px 8px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  padding: 10px 0 27px 25px;
  min-height: 415px;
  border: 1px solid #F2F2F2; }
  @media (max-width: 992px) {
    .nav_items {
      display: none;
      border: none; } }
  .nav_items.active {
    display: flex;
    position: relative;
    left: 10;
    z-index: 10;
    border-radius: 8px; }
    .nav_items.active .nav__item_content {
      padding: 10px 0 27px 25px;
      position: absolute;
      top: 0;
      left: 50%; }
  .nav_items.fixed {
    top: 50px; }
  .nav_items.mobile {
    display: block;
    position: fixed;
    top: 100px;
    left: 0;
    z-index: 11;
    background: #54AF37;
    width: 295px;
    border-radius: 0px;
    overflow-y: auto;
    height: calc(100% - 100px); }
    .nav_items.mobile .nav__item {
      color: white; }

.nav__item {
  margin-top: 15px;
  color: #333333;
  max-width: 250px;
  width: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: 0.1s; }
  @media (min-width: 992px) {
    .nav__item:hover {
      transform: scale(1.05);
      color: green;
      background: rgba(0, 128, 0, 0.2); } }
  .nav__item img {
    margin-right: 10px;
    width: 16px;
    height: 16px;
    object-fit: cover; }
  .nav__item.active {
    color: green;
    font-weight: bold; }
  .nav__item-main {
    position: relative; }

.nav__item_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .nav__item_wrapper div {
    max-width: 200px; }
  .nav__item_wrapper.active .nav__item-main {
    color: green;
    background: rgba(0, 128, 0, 0.2);
    font-weight: bold;
    border-radius: 3px; }
  .nav__item_wrapper.hidden {
    display: none; }

.nav__item_content {
  transition: all .2s;
  visibility: hidden;
  max-height: 1px;
  height: auto;
  display: none;
  flex-direction: column;
  width: fit-content; }
  .nav__item_content a {
    margin-left: 25px; }
  .nav__item_content.active {
    display: flex;
    visibility: visible;
    max-height: 1000px; }

.nav__item_content_container {
  position: absolute;
  width: 300px;
  top: 50px;
  z-index: 200;
  background: white; }
  .nav__item_content_container_1 {
    left: 300px; }
  .nav__item_content_container_2 {
    left: 600px;
    border-radius: 0 8px 8px 0; }
  .nav__item_content_container.active {
    height: calc(100% - 50px);
    overflow: scroll;
    border: 1px solid #F2F2F2; }

.nav__item-second.active {
  color: green;
  background: rgba(0, 128, 0, 0.2);
  font-weight: bold;
  border-radius: 3px; }

.img_green,
.img_white {
  display: block; }
  .img_green.active,
  .img_white.active {
    display: none; }

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  background: #F7F7F9; }
  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: 15px;
    color: #737988; }

.swiper-button-prev {
  left: 0; }

.swiper-button-next {
  right: 40px; }

.swiper-button-disabled {
  display: none; }
