.footer {
  background: #FFFFFF; }
  .footer_main {
    display: flex;
    color: #737988;
    margin-top: 40px; }
    @media (max-width: 992px) {
      .footer_main {
        flex-direction: column; } }
    .footer_main .footer_column-left {
      width: 55%;
      padding-top: 40px; }
      @media (max-width: 992px) {
        .footer_main .footer_column-left {
          width: 100%; } }
      .footer_main .footer_column-left .footer_middle {
        display: flex; }
        @media (max-width: 600px) {
          .footer_main .footer_column-left .footer_middle {
            flex-direction: column; } }
        .footer_main .footer_column-left .footer_middle .footer_contacts {
          width: 50%; }
          @media (max-width: 600px) {
            .footer_main .footer_column-left .footer_middle .footer_contacts {
              width: 100%; } }
          .footer_main .footer_column-left .footer_middle .footer_contacts__title {
            font-size: 18px;
            display: flex; }
            .footer_main .footer_column-left .footer_middle .footer_contacts__title .header_sociate__block {
              margin-left: 30px; }
          .footer_main .footer_column-left .footer_middle .footer_contacts .footer__inform-service {
            font-size: 14px; }
          .footer_main .footer_column-left .footer_middle .footer_contacts .footer__number a {
            font-size: 24px;
            white-space: nowrap; }
          .footer_main .footer_column-left .footer_middle .footer_contacts div {
            margin-top: 15px; }
        .footer_main .footer_column-left .footer_middle .footer_links {
          width: 100%;
          display: flex;
          justify-content: space-around;
          margin-top: 10px; }
          @media (max-width: 600px) {
            .footer_main .footer_column-left .footer_middle .footer_links {
              justify-content: space-between; } }
          .footer_main .footer_column-left .footer_middle .footer_links_column {
            display: flex;
            flex-direction: column; }
            .footer_main .footer_column-left .footer_middle .footer_links_column a {
              margin-top: 10px;
              font-size: 14px; }
    .footer_main .footer_contact-us {
      width: 45%;
      padding-top: 40px; }
      @media (max-width: 992px) {
        .footer_main .footer_contact-us {
          width: 100%; } }
      .footer_main .footer_contact-us__title {
        font-size: 24px;
        color: #333333;
        font-weight: 800; }
      .footer_main .footer_contact-us form {
        margin-top: 30px; }
        .footer_main .footer_contact-us form input {
          height: 49px;
          margin: 0 10px 10px 0; }
          @media (max-width: 600px) {
            .footer_main .footer_contact-us form input {
              width: 100%; } }
  .footer .footer_bottom {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px; }
    @media (max-width: 992px) {
      .footer .footer_bottom {
        flex-direction: column;
        align-items: center; }
        .footer .footer_bottom div {
          margin-bottom: 20px; } }
    .footer .footer_bottom .footer_payment a {
      margin-right: 10px; }
    .footer .footer_bottom .star-way__logo {
      height: 50px; }
      .footer .footer_bottom .star-way__logo img {
        width: 120px;
        height: 50px; }

a {
  cursor: pointer; }
