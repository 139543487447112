.page-404_wrapper {
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  padding: 7%; }
  @media (max-width: 1110px) {
    .page-404_wrapper {
      flex-direction: column; } }

.page-404_text {
  width: 35%;
  min-width: 150px;
  color: #737988; }
  @media (max-width: 1110px) {
    .page-404_text {
      width: 100%; } }
  .page-404_text__title {
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 20px; }
  .page-404_text__description {
    font-weight: 300;
    font-size: 20px; }
  .page-404_text__btn {
    margin-top: 20px; }

.page-404_img img {
  width: 100%; }

.nav_items {
  display: none; }
