.btn_standart {
  background: #54AF37;
  border-radius: 8px;
  color: white;
  height: 50px;
  font-weight: bold;
  cursor: pointer;
  padding: 10px; }
  .btn_standart:hover {
    box-shadow: 0px 5px 5px rgba(139, 139, 139, 0.29); }

.register_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  color: #54AF37;
  font-weight: bold;
  font-size: 15px;
  border: 1px solid #54AF37;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer; }

input[type="submit"] {
  border: none; }
