.services_wrapper {
  margin-top: 40px;
  display: flex;
  align-items: stretch; }
  .services_wrapper .services__item {
    background: #FFFFFF;
    border-left: 1px solid #F3F3F3;
    display: flex;
    align-items: center;
    padding: 40px 50px;
    height: auto; }
    @media (max-width: 1410px) {
      .services_wrapper .services__item {
        padding: 20px; } }
    .services_wrapper .services__item:first-child {
      border-radius: 12px 0 0 12px; }
    .services_wrapper .services__item:last-child {
      border-radius: 0 12px 12px 0; }
    .services_wrapper .services__item .description {
      margin-left: 20px; }
      .services_wrapper .services__item .description__title {
        font-size: 16px;
        color: #333333;
        font-weight: 600; }
      .services_wrapper .services__item .description__text {
        max-width: 215px;
        font-size: 14px;
        color: #373843;
        opacity: 0.5; }
