.basket_modal_wrapper {
  display: none;
  transition: all .2s;
  padding: 50px 60px;
  overflow-y: auto;
  max-height: calc(100% - 97px);
  max-width: 500px;
  width: 100%;
  background: #fff;
  position: fixed;
  top: 87px;
  left: 100%;
  border-radius: 8px;
  transform: translateX(-115%);
  -webkit-box-shadow: 14px 10px 13px -6px #bdbdbd;
  -moz-box-shadow: 14px 10px 13px -6px #bdbdbd;
  box-shadow: 14px 10px 13px -6px #bdbdbd; }
  @media (max-width: 992px) {
    .basket_modal_wrapper {
      transform: translateX(-100%);
      top: 110px;
      max-height: calc(100% - 110px); } }
  @media (max-width: 650px) {
    .basket_modal_wrapper {
      left: 50%;
      transform: translateX(-50%);
      top: 100px;
      max-height: calc(100% - 100px);
      border-radius: 0; } }
  @media (max-width: 500px) {
    .basket_modal_wrapper {
      padding: 30px 15px 15px 15px; } }
  .basket_modal_wrapper.active {
    display: block;
    z-index: 9999; }
  .basket_modal_wrapper .basket_modal_close {
    transition: all .2s;
    color: #0000007a;
    cursor: pointer;
    display: flex;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 20px;
    right: 20px; }
    .basket_modal_wrapper .basket_modal_close:hover {
      color: #73CAC2; }
  .basket_modal_wrapper .basket_product {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    margin-bottom: 40px;
    border-bottom: 1px solid rgba(12, 6, 26, 0.2); }
    .basket_modal_wrapper .basket_product_cart {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      align-items: center; }
      .basket_modal_wrapper .basket_product_cart_wrapper {
        width: 70%; }
      .basket_modal_wrapper .basket_product_cart__name {
        width: 100%;
        color: #000000;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 15px;
        word-wrap: break-word; }
      .basket_modal_wrapper .basket_product_cart .product_quantity_wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 25px;
        border-bottom: 1px solid rgba(12, 6, 26, 0.05); }
        @media (max-width: 400px) {
          .basket_modal_wrapper .basket_product_cart .product_quantity_wrapper {
            flex-direction: column;
            justify-content: center; } }
        @media (max-width: 400px) {
          .basket_modal_wrapper .basket_product_cart .product_quantity_wrapper .product_price_wrapper {
            margin-top: 15px; } }
      .basket_modal_wrapper .basket_product_cart__img {
        padding: 21px 18px;
        background: #FFFFFF;
        border-radius: 12px;
        margin-right: 40px;
        border: 1px solid #F3F3F3;
        box-sizing: border-box;
        transform: matrix(-1, 0, 0, 1, 0, 0); }
        .basket_modal_wrapper .basket_product_cart__img img {
          width: 56px;
          height: 50px;
          object-fit: contain; }
    .basket_modal_wrapper .basket_product_info {
      width: 100%; }
      .basket_modal_wrapper .basket_product_info_block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
        flex-wrap: wrap; }
    .basket_modal_wrapper .basket_product .product_price_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap; }
      .basket_modal_wrapper .basket_product .product_price_wrapper .product_price__value {
        display: flex;
        align-items: center;
        justify-content: center; }
    @media (max-width: 300px) {
      .basket_modal_wrapper .basket_product__remove {
        margin-top: 20px; } }
    .basket_modal_wrapper .basket_product__remove button {
      display: flex;
      align-items: center;
      font-size: 13px;
      color: #737988;
      opacity: 0.6; }
      .basket_modal_wrapper .basket_product__remove button img {
        margin-right: 12px; }

.basket_modal_bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px; }
  .basket_modal_bottom .total_sum__title {
    color: #373843;
    opacity: 0.6; }
  .basket_modal_bottom .total_sum__value {
    display: flex;
    font-size: 30px; }
    .basket_modal_bottom .total_sum__value-number {
      margin-right: 10px; }
  .basket_modal_bottom .basket_modal__btn {
    white-space: nowrap; }
    .basket_modal_bottom .basket_modal__btn a {
      padding: 15px; }
  .basket_modal_bottom_block {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .basket_modal_bottom_block .return {
      margin-top: 20px; }
    .basket_modal_bottom_block .ordering a {
      white-space: nowrap;
      padding: 15px; }
