@charset "UTF-8";
.goods__cart {
  background: #FFFFFF;
  margin: 20px 20px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 23%;
  height: auto;
  border-radius: 8px; }
  @media (max-width: 1700px) {
    .goods__cart {
      width: 31%; } }
  @media (max-width: 1320px) {
    .goods__cart {
      width: 45%; } }
  @media (max-width: 600px) {
    .goods__cart {
      width: 90%;
      margin: 20px 0px 0 0; } }
  .goods__cart:hover {
    border: 1px solid #54AF37; }
  .goods__cart_top {
    height: 80%; }
  .goods__cart .goods_status {
    width: 100%;
    display: flex;
    position: absolute;
    z-index: 3;
    flex-wrap: wrap;
    padding: 10px; }
    .goods__cart .goods_status div {
      margin-right: 5px;
      margin-bottom: 5px;
      border-radius: 6px;
      padding: 5px 10px;
      text-transform: uppercase;
      font-size: 10px; }
    .goods__cart .goods_status .orange {
      background: #FFA600;
      color: #FFFFFF; }
    .goods__cart .goods_status .white {
      color: #54AF37; }
    .goods__cart .goods_status .red {
      background: #EB5757;
      color: #FFFFFF; }
  .goods__cart .goods__img {
    margin-top: 20px;
    width: 210px;
    height: 210px;
    position: relative;
    left: 50%;
    transform: translateX(-50%); }
    .goods__cart .goods__img img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .goods__cart .goods__name {
    font-size: 16px;
    color: #333333;
    padding: 10px; }
  .goods__cart .goods__description {
    font-size: 12px;
    color: #373843;
    opacity: 0.6;
    padding-left: 10px; }
  .goods__cart .goods_rating {
    padding-left: 10px; }
    .goods__cart .goods_rating a {
      display: flex;
      align-items: center;
      flex-wrap: wrap; }
      .goods__cart .goods_rating a .revews {
        margin-left: 10px;
        color: #373843;
        font-size: 12px; }
  .goods__cart .goods_bottom {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    background: #E6E6E6;
    border-radius: 0px 0px 8px 8px; }
    .goods__cart .goods_bottom .goods__btn button {
      width: 60px;
      min-height: 50px;
      height: 100%;
      border-radius: 0 0 8px 0; }
      .goods__cart .goods_bottom .goods__btn button:hover {
        width: 100%; }
      .goods__cart .goods_bottom .goods__btn button:hover::after {
        content: 'Купити'; }
    .goods__cart .goods_bottom .goods__btn:hover {
      width: 40%; }

.goods__price {
  padding-left: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-weight: bold;
  font-size: 20px; }
  .goods__price_wrapper {
    display: flex; }
  .goods__price-old {
    text-decoration-line: line-through;
    color: #333333;
    font-weight: 300;
    margin-right: 10px; }
  .goods__price-new {
    color: #EB5757; }

.sale_category {
  min-height: 300px;
  overflow: hidden;
  position: relative; }
  .sale_category a {
    position: absolute;
    width: 100%;
    height: 100%; }
  .sale_category .sale__title {
    font-weight: bold;
    font-size: 20px;
    color: #FFA600;
    width: 150px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    .sale_category .sale__title::after {
      content: '→'; }

.title {
  margin-top: 49px;
  font-size: 24px;
  color: #737988; }

.rating-mini {
  display: inline-block;
  font-size: 0; }

.rating-mini span {
  padding: 0;
  font-size: 20px;
  line-height: 1;
  color: lightgrey; }

.rating-mini > span:before {
  content: '★'; }

.rating-mini > span.active {
  color: gold; }
