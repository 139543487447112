.newsletter_wrapper {
  margin: 5%;
  display: flex;
  border-radius: 8px; }
  @media (max-width: 992px) {
    .newsletter_wrapper {
      flex-direction: column; } }

.newsletter__img img {
  width: 100%; }

.newsletter_subscribe {
  width: 100%;
  padding: 30px;
  color: #737988; }
  .newsletter_subscribe__title {
    font-weight: 800;
    font-size: 24px; }
  .newsletter_subscribe__text {
    font-size: 14px; }
  .newsletter_subscribe__form {
    width: 100%;
    margin-top: 20px;
    display: flex; }
    @media (max-width: 500px) {
      .newsletter_subscribe__form {
        display: flex;
        flex-direction: column; } }
    .newsletter_subscribe__form .input_wrapper {
      width: 540px; }
      @media (max-width: 500px) {
        .newsletter_subscribe__form .input_wrapper {
          width: auto; } }
      .newsletter_subscribe__form .input_wrapper input {
        width: 100%; }
        .newsletter_subscribe__form .input_wrapper input:hover {
          border: 1px solid; }
    .newsletter_subscribe__form .btn {
      text-transform: lowercase; }
      @media (max-width: 500px) {
        .newsletter_subscribe__form .btn {
          margin-top: 20px; } }
      .newsletter_subscribe__form .btn::first-letter {
        text-transform: uppercase; }
