.header {
  position: fixed;
  z-index: 999;
  width: 100%;
  background: white; }
  .header_top {
    display: flex;
    align-items: center;
    background: black;
    padding: 5px 5%; }
    .header_top__text {
      width: 99%;
      text-align: center;
      color: #fff;
      font-size: 14px; }
      @media (max-width: 600px) {
        .header_top__text {
          font-size: 8px; } }
    .header_top__close {
      width: 1%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12px;
      height: 12px;
      cursor: pointer; }
      .header_top__close svg {
        width: 100%;
        height: 100%; }
  .header_main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px; }
    @media (max-width: 992px) {
      .header_main {
        display: none; } }
    .header_main div {
      margin-right: 20px; }
    .header_main__logo a {
      display: flex;
      flex-wrap: nowrap;
      align-items: center; }
      .header_main__logo a img:first-child {
        margin-right: 15px; }
      @media (max-width: 1100px) {
        .header_main__logo a img:last-child {
          display: none; } }
    .header_main_button {
      display: flex;
      align-items: center; }
      .header_main_button .header_button__login {
        width: auto;
        padding: 0 30px;
        height: 50px;
        border-radius: 8px;
        border: 1px solid #E0E0E0;
        margin-right: 15px;
        display: flex;
        justify-content: center;
        align-items: center; }
        @media (max-width: 992px) {
          .header_main_button .header_button__login {
            width: 50px;
            border-radius: 0;
            border: none;
            margin-right: 0; } }
        .header_main_button .header_button__login img {
          margin-right: 10px; }
          @media (max-width: 992px) {
            .header_main_button .header_button__login img {
              margin-right: 0px; } }
        .header_main_button .header_button__login:hover {
          border: solid 1px #bdd2d0 !important;
          background: #bdd2d0;
          color: #fff; }
      .header_main_button .header_button__basket {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        height: 50px; }
        @media (max-width: 992px) {
          .header_main_button .header_button__basket {
            width: 50px;
            border-radius: 0; } }
        .header_main_button .header_button__basket img {
          margin: 0 10px; }
        .header_main_button .header_button__basket .header_basket_count_wrapper {
          position: relative;
          width: 50px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center; }
          .header_main_button .header_button__basket .header_basket_count_wrapper img {
            width: 30px;
            height: 30px; }
          .header_main_button .header_button__basket .header_basket_count_wrapper .header_basket_count-mob {
            position: absolute;
            font-size: 14px;
            top: 30%;
            color: #54AF37;
            z-index: 100; }
        .header_main_button .header_button__basket .header_basket_count {
          margin-left: 10px;
          white-space: nowrap; }
          .header_main_button .header_button__basket .header_basket_count::after {
            content: ')'; }
          .header_main_button .header_button__basket .header_basket_count::before {
            content: '('; }
    .header_main_info {
      white-space: nowrap; }
  .header_mob {
    display: none; }
    @media (max-width: 992px) {
      .header_mob {
        display: block; } }
    .header_mob_top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px; }
    .header_mob_bottom {
      display: flex;
      justify-content: space-between;
      height: 50px; }
      .header_mob_bottom .catalog__btn_wrapper .catalog__btn {
        border-radius: 0;
        display: flex;
        align-items: center;
        white-space: nowrap;
        height: 50px;
        width: auto; }
        .header_mob_bottom .catalog__btn_wrapper .catalog__btn .dot_container {
          width: 21px;
          height: 21px;
          position: relative;
          margin-right: 10px; }
          .header_mob_bottom .catalog__btn_wrapper .catalog__btn .dot_container .dot__item {
            transition: all .2s;
            position: absolute;
            width: 4.57px;
            height: 4.57px;
            border-radius: 50%;
            background: white; }
            .header_mob_bottom .catalog__btn_wrapper .catalog__btn .dot_container .dot__item_1 {
              left: 0px;
              top: 0px; }
            .header_mob_bottom .catalog__btn_wrapper .catalog__btn .dot_container .dot__item_2 {
              left: 8.22px;
              top: 0px; }
            .header_mob_bottom .catalog__btn_wrapper .catalog__btn .dot_container .dot__item_3 {
              left: 16.43px;
              top: 0px; }
            .header_mob_bottom .catalog__btn_wrapper .catalog__btn .dot_container .dot__item_4 {
              left: 0px;
              top: 8.22px; }
            .header_mob_bottom .catalog__btn_wrapper .catalog__btn .dot_container .dot__item_5 {
              left: 8.22px;
              top: 8.22px; }
            .header_mob_bottom .catalog__btn_wrapper .catalog__btn .dot_container .dot__item_6 {
              left: 16.43px;
              top: 8.22px; }
            .header_mob_bottom .catalog__btn_wrapper .catalog__btn .dot_container .dot__item_7 {
              left: 8.22px;
              top: 16.43px; }
          .header_mob_bottom .catalog__btn_wrapper .catalog__btn .dot_container.active .dot__item_1 {
            left: 8.22px;
            top: 0px;
            transform: rotate(45deg); }
          .header_mob_bottom .catalog__btn_wrapper .catalog__btn .dot_container.active .dot__item_2 {
            left: 0px;
            top: 8.22px;
            transform: rotate(45deg); }
          .header_mob_bottom .catalog__btn_wrapper .catalog__btn .dot_container.active .dot__item_3 {
            left: 8.22px;
            top: 8.22px;
            transform: rotate(45deg); }
          .header_mob_bottom .catalog__btn_wrapper .catalog__btn .dot_container.active .dot__item_4 {
            left: 16.43px;
            top: 8.22px;
            transform: rotate(45deg); }
          .header_mob_bottom .catalog__btn_wrapper .catalog__btn .dot_container.active .dot__item_5 {
            left: 0px;
            top: 16.43px;
            transform: rotate(45deg); }
          .header_mob_bottom .catalog__btn_wrapper .catalog__btn .dot_container.active .dot__item_6 {
            left: 8.22px;
            top: 16.43px;
            transform: rotate(45deg); }
          .header_mob_bottom .catalog__btn_wrapper .catalog__btn .dot_container.active .dot__item_7 {
            left: 16.43px;
            top: 16.43px;
            transform: rotate(45deg); }
  .header_hamburger {
    overflow: hidden;
    position: relative;
    width: 50px;
    height: 50px;
    background: #F2F2F2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .header_line {
    transition: all .2s;
    position: absolute;
    width: 21px;
    height: 3px;
    border-radius: 18px;
    margin: 4px 0;
    cursor: pointer;
    background: #54AF37; }
    .header_line_1 {
      top: 16px;
      left: 14px; }
    .header_line_2 {
      top: 22px;
      left: 14px; }
    .header_line_3 {
      top: 28px;
      left: 14px; }

.header_main_phone {
  display: flex;
  align-items: center;
  margin-bottom: 5px; }
  .header_main_phone span {
    transition: all .2s; }
  .header_main_phone svg {
    margin-right: 3px; }
  .header_main_phone_info {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .header_main_phone:hover span {
    color: #CF1A20; }

.header_phone_arrow {
  transition: all .2s;
  margin-left: 10px;
  position: relative;
  top: -2px;
  cursor: pointer; }
  .header_phone_arrow:hover {
    transform: scale(1.3); }

.header_another_phone {
  transition: all .2s;
  position: absolute;
  top: 50px;
  opacity: 0;
  max-height: 1px;
  visibility: hidden;
  height: auto;
  background: #fff;
  padding: 5px 15px;
  margin-left: -14px; }
  .header_another_phone.active {
    opacity: 1;
    top: 35px;
    visibility: visible;
    max-height: 1000px;
    z-index: 9; }

.header.active .header_line_1 {
  top: 22px;
  left: 15px;
  transform: rotate(45deg); }

.header.active .header_line_2 {
  left: -100px; }

.header.active .header_line_3 {
  top: 22px;
  left: 15px;
  transform: rotate(-45deg); }

.header_search {
  transition: all .2s;
  width: 300px;
  height: 50px;
  padding-left: 15px;
  padding-right: 45px;
  border: 1px solid #F2F2F2;
  border-radius: 8px; }
  @media (max-width: 1350px) {
    .header_search {
      max-width: 300px;
      width: 100%; } }
  @media (max-width: 992px) {
    .header_search {
      border: none;
      background: #F2F2F2;
      border-radius: 0;
      max-width: none;
      width: 100%; } }
  .header_search__block {
    position: relative;
    margin-right: 15px; }
    @media (max-width: 992px) {
      .header_search__block {
        margin-right: 0;
        width: 100%; } }
    .header_search__block img {
      position: absolute;
      cursor: default;
      right: 15px;
      top: 50%;
      transform: translateY(-50%); }
    .header_search__block:hover {
      transform: scale(1) !important; }
      .header_search__block:hover input {
        border: 1px solid #54AF37; }

.number-link {
  display: flex;
  align-items: center; }

.overlay {
  transition: all .2s;
  background-color: rgba(0, 0, 0, 0.708);
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: -1;
  bottom: -100%;
  right: 0;
  opacity: 0;
  overflow: hidden; }
  .overlay.active {
    z-index: 9;
    bottom: 0;
    opacity: 1; }

.icons {
  display: flex; }
  .icons a img {
    width: 20px;
    height: 20px;
    margin-right: 10px; }
