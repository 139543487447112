.product_quantity {
  display: flex;
  align-items: center;
  justify-content: center; }
  .product_quantity button {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 7px 15px;
    color: #333333;
    font-weight: bold; }
  .product_quantity .basket__btn {
    background: #F7F7F9;
    border-radius: 8px;
    padding: 7px 15px;
    color: #333333;
    font-weight: bold; }
  .product_quantity__value {
    margin: 0 20px;
    font-weight: bold;
    font-size: 15px;
    color: #373843; }

.product_price__value {
  margin-left: 20px;
  font-weight: 600;
  font-size: 18px; }
